/* tslint:disable */
/* eslint-disable */
export enum CommitteeTypeEnum {
  Management = 'Management',
  Design = 'Design',
  CostPreparation = 'CostPreparation',
  ExecuterDecision = 'ExecuterDecision',
  ReceiveInitial = 'ReceiveInitial',
  ReceiveFinal = 'ReceiveFinal',
  Other = 'Other'
}
