import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum RefreshSubjectKeys {
    ProjectsRequestsEng,
    ProjectsRequestsExe,
    AdminUsers,
    AdminCompaniesGov,
    AdminCompaniesExe,
    projectDetailsCommitteeLeader
}
@Injectable({ providedIn: 'root' })
export class RefreshSubjectService {
    constructor() { }

    refreshSubject: { [key in RefreshSubjectKeys]?: Subject<boolean> } = {};
    getSubject(key: RefreshSubjectKeys): Subject<boolean> {
        let res = this.refreshSubject[key];
        if (!res) {
            res = this.refreshSubject[key] = new Subject<boolean>();
        }
        return res;
    }
}
