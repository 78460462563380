/* tslint:disable */
/* eslint-disable */
export enum DateBasedOn {
  CreationDate = 'CreationDate',
  StartDate = 'StartDate',
  EndDate = 'EndDate',
  InitialWarrantyStart = 'InitialWarrantyStart',
  InitialWarrantyEnd = 'InitialWarrantyEnd',
  FinalWarrantyStart = 'FinalWarrantyStart',
  FinalWarrantyEnd = 'FinalWarrantyEnd'
}
