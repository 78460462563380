/* tslint:disable */
/* eslint-disable */
export enum TransactionActionTypeEnum {
  CreateProject = 'CreateProject',
  ExecutiveManagerConfirmProjectRequest = 'ExecutiveManager_ConfirmProjectRequest',
  UpdateProject = 'UpdateProject',
  EditCommitteeTeams = 'EditCommitteeTeams',
  EditCommitteeTeamMembers = 'EditCommitteeTeamMembers',
  AddTask = 'AddTask',
  EngManagementDirectorEditEngInfo = 'EngManagementDirector_EditEngInfo',
  UpdateTask = 'UpdateTask',
  DeleteTask = 'DeleteTask',
  AddCommentToTask = 'AddCommentToTask',
  CompleteTask = 'CompleteTask',
  CreateCommittee = 'CreateCommittee',
  UpdateCommittee = 'UpdateCommittee',
  DeleteCommittee = 'DeleteCommittee',
  DevDataEntry = 'Dev_DataEntry',
  AddExtract = 'AddExtract',
  UpdateExtract = 'UpdateExtract',
  DeleteExtract = 'DeleteExtract',
  AddTechnicalReport = 'AddTechnicalReport',
  UpdateTechnicalReport = 'UpdateTechnicalReport',
  DeleteTechnicalReport = 'DeleteTechnicalReport'
}
