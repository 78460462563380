/* tslint:disable */
/* eslint-disable */
export enum Roles {
  President = 'President',
  ExecutiveManager = 'ExecutiveManager',
  EngManagementDirector = 'EngManagementDirector',
  SystemAdmin = 'SystemAdmin',
  NormalUser = 'NormalUser',
  Developer = 'Developer'
}
